import type {PiniaPluginContext} from 'pinia'

/**
 * Todo revoir typage + ajouter options dans le store pour trigger des event une fois
 *      le store patché, ce qui pourrais permettre de run la validation par exemple.
 *
 * @param obj
 * @param path
 * @param value
 */

function setObjectValueByPath(obj: NonNullable<unknown>, path: string, value: unknown): void {
    const keys = path.split('.')
    let current = obj
    while (keys.length > 1) {
        const key = keys.shift() as string
        if (!(key in current)) {
            current[key] = {}
        }
        current = current[key]
    }
    current[keys[0]] = value
}

export function PiniaQueryPlugin({store}: PiniaPluginContext) {
    store.updateFromQuery = function (queryString: string, path: string) {
        const urlParams: URLSearchParams = new URLSearchParams(queryString)
        urlParams.forEach((value: string, key: string) => {
            setObjectValueByPath(store.$state[path], key, value)
        })
    }
}

declare module 'pinia' {
    export interface PiniaCustomProperties {
        /**
         * Exemple amount_cover=2500
         *
         * @param queryString
         * @param path
         */
        updateFromQuery: (queryString: string, path: string) => void;
    }
}
