import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/opt/build/repo/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/build/repo/.nuxt/sentry-client-config.mjs";
import plugin_eskviOYyLt from "/opt/build/repo/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import plugin_WLsn00x1qh from "/opt/build/repo/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_tbFNToZNim from "/opt/build/repo/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_ghbUAjaD3n from "/opt/build/repo/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_vtYfpS1JtD from "/opt/build/repo/pinia/plugin/persist/runtime/plugin.ts";
import _00_domain_configuration_MMoRYFQM4q from "/opt/build/repo/plugins/00.domain-configuration.ts";
import _01_form_configuration_S4dSzI60Wt from "/opt/build/repo/plugins/01.form_configuration.ts";
import _02_event_bus_OGwpbEIL6A from "/opt/build/repo/plugins/02.event-bus.ts";
import _03_event_dispatcher_client_N08LJ30gtu from "/opt/build/repo/plugins/03.event-dispatcher.client.ts";
import _04_ve_progress_client_vyRjQIQBYx from "/opt/build/repo/plugins/04.ve-progress.client.ts";
import _05_vue_transition_group_bw6bHxa9HV from "/opt/build/repo/plugins/05.vue-transition-group.ts";
import pinia_plugin_3b4ZAtDDk3 from "/opt/build/repo/plugins/pinia-plugin.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_eskviOYyLt,
  plugin_WLsn00x1qh,
  plugin_tbFNToZNim,
  plugin_ghbUAjaD3n,
  plugin_vtYfpS1JtD,
  _00_domain_configuration_MMoRYFQM4q,
  _01_form_configuration_S4dSzI60Wt,
  _02_event_bus_OGwpbEIL6A,
  _03_event_dispatcher_client_N08LJ30gtu,
  _04_ve_progress_client_vyRjQIQBYx,
  _05_vue_transition_group_bw6bHxa9HV,
  pinia_plugin_3b4ZAtDDk3
]