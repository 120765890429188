import { default as indexI7gC0Vq0chMeta } from "/opt/build/repo/pages/comparateur/car/form/index.vue?macro=true";
import { default as informationsrGglQ6lP53Meta } from "/opt/build/repo/pages/comparateur/car/form/subscribers/[profile]/informations.vue?macro=true";
import { default as brandMOc1eUKHyVMeta } from "/opt/build/repo/pages/comparateur/car/form/vehicle/brand.vue?macro=true";
import { default as datesQxJCdHNSjkMeta } from "/opt/build/repo/pages/comparateur/car/form/vehicle/dates.vue?macro=true";
import { default as energybI2FRT3AkTMeta } from "/opt/build/repo/pages/comparateur/car/form/vehicle/energy.vue?macro=true";
import { default as modeladBfC1cHGwMeta } from "/opt/build/repo/pages/comparateur/car/form/vehicle/model.vue?macro=true";
import { default as powerT0yBC1vvyCMeta } from "/opt/build/repo/pages/comparateur/car/form/vehicle/power.vue?macro=true";
import { default as usageuiAKRmVGffMeta } from "/opt/build/repo/pages/comparateur/car/form/vehicle/usage.vue?macro=true";
import { default as versionSaEuJNSFFSMeta } from "/opt/build/repo/pages/comparateur/car/form/vehicle/version.vue?macro=true";
import { default as formnTXmdrPqXgMeta } from "/opt/build/repo/pages/comparateur/car/form.vue?macro=true";
import { default as index7BXJ05QAIDMeta } from "/opt/build/repo/pages/comparateur/car/restitution/[uuid]/index.vue?macro=true";
import { default as _91offerId_93M9LiaUlC4MMeta } from "/opt/build/repo/pages/comparateur/car/restitution/[uuid]/mise-en-relation-[mode]/[offerId].vue?macro=true";
import { default as _91uuid_93tWxCkawGvNMeta } from "/opt/build/repo/pages/comparateur/car/restitution/[uuid].vue?macro=true";
import { default as carznkjMX4WwbMeta } from "/opt/build/repo/pages/comparateur/car.vue?macro=true";
import { default as indexPWSFfIoZKYMeta } from "/opt/build/repo/pages/comparateur/example/form/index.vue?macro=true";
import { default as informationc30xkKueDiMeta } from "/opt/build/repo/pages/comparateur/example/form/information.vue?macro=true";
import { default as _91profil_93X5RQhvLz8UMeta } from "/opt/build/repo/pages/comparateur/example/form/profil/[profil].vue?macro=true";
import { default as test2uzBtVmvYhMeta } from "/opt/build/repo/pages/comparateur/example/form/test.vue?macro=true";
import { default as formdbOGjJwhQHMeta } from "/opt/build/repo/pages/comparateur/example/form.vue?macro=true";
import { default as _91mode_93vUwr2P5ixVMeta } from "/opt/build/repo/pages/comparateur/example/restitution/[uuid]/proposal/[mode].vue?macro=true";
import { default as _91uuid_932iyEUjqfKRMeta } from "/opt/build/repo/pages/comparateur/example/restitution/[uuid].vue?macro=true";
import { default as indexccmxu3bNK1Meta } from "/opt/build/repo/pages/comparateur/funeral-v2/form/index.vue?macro=true";
import { default as informationdfxBMceDcrMeta } from "/opt/build/repo/pages/comparateur/funeral-v2/form/information.vue?macro=true";
import { default as formvJJZ5o3ruHMeta } from "/opt/build/repo/pages/comparateur/funeral-v2/form.vue?macro=true";
import { default as indexx2v2QMqR1LMeta } from "/opt/build/repo/pages/comparateur/funeral-v2/restitution/[uuid]/index.vue?macro=true";
import { default as _91offerId_93ijpJO71hHxMeta } from "/opt/build/repo/pages/comparateur/funeral-v2/restitution/[uuid]/mise-en-relation-[mode]/[offerId].vue?macro=true";
import { default as _91uuid_93E86CLDUksmMeta } from "/opt/build/repo/pages/comparateur/funeral-v2/restitution/[uuid].vue?macro=true";
import { default as funeral_45v263CBkSfqt9Meta } from "/opt/build/repo/pages/comparateur/funeral-v2.vue?macro=true";
import { default as comparateurnTtsEHbPdSMeta } from "/opt/build/repo/pages/comparateur.vue?macro=true";
import { default as iframe_45testerV4lDRihEFDMeta } from "/opt/build/repo/pages/iframe-tester.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
export default [
  {
    name: "comparateur",
    path: "/comparateur",
    component: () => import("/opt/build/repo/pages/comparateur.vue"),
    children: [
  {
    name: "comparateur-car",
    path: "car",
    alias: ["/comparateur/assurance-auto-a39b2159-128d-4943-b5f5-e8f9e0d25b6b/","/comparateur/assurance-auto-e7cc1582-85cd-4db1-a8a0-d8bb846753f3/","/comparateur/assurance-auto-20fe5dae-08c1-464e-b2bd-5e1b25f621ca/","/comparateur/assurance-auto-7970ad0c-9216-451b-8c56-ba5e21d9d7eb/","/comparateur/assurance-auto-d6fc5abc-3f03-4ac0-b184-bccae739a088/","/comparateur/assurance-auto-e4b2e890-2ed4-48fa-a98d-fd591aa75a64/","/comparateur/assurance-auto-f5d19ce8-e3d9-4cf4-abf7-c983d352e55a/","/comparateur/assurance-auto-e32dbf87-04bc-4ef7-a1c6-00a84caa5b77/","/comparateur/assurance-auto-2bf0923c-9ee4-498d-baf7-f22729f1d1f2/","/comparateur/assurance-auto-413d861a-6ec1-4489-a98c-ea02d82338bd/","/comparateur/assurance-auto-7058c039-a5bd-4c0f-a315-9daa7c27bffe/","/comparateur/assurance-auto-83f300c2-9deb-4c47-b6f0-305c417c780e/","/comparateur/assurance-auto-140c814a-ec2d-42c6-9bb6-9f907fb7bd07/","/comparateur/assurance-auto-50a7c628-5037-49b0-bd42-49ebdf4efa14/","/comparateur/assurance-auto-ab6950dd-9e5c-4788-8e43-81cd47eb3077/","/comparateur/assurance-auto-ea023a41-3e36-44ff-8c63-bfc670c75730/","/comparateur/assurance-auto-945231e9-fe82-4acd-a6a5-9c4de2d0dab8/","/comparateur/assurance-auto-8d97e1ef-299d-4fa1-9873-59eb9c5fd513/","/comparateur/assurance-auto-2d215c71-9b9c-491f-9be1-f39543600fd0/","/comparateur/assurance-auto-2bc4ef49-51d5-456c-9cef-6cd2533baefb/","/comparateur/assurance-auto-e47890e6-8f15-4afa-8f1b-3829a39a67ce/","/comparateur/assurance-auto-223e5148-d55b-4bb4-bd42-e27f835ab860/","/comparateur/assurance-auto-c752d24e-b343-41bb-8076-a5079f6f5dff/","/comparateur/assurance-auto-e7cc8da8-de5f-4cd5-91eb-a3040fcda722/","/comparateur/assurance-auto-b8f20c35-c5b1-46f5-b153-6cb839ef51e6/","/comparateur/assurance-auto-368eadac-4c67-4de2-a896-5fe185b7b69f/","/comparateur/assurance-auto-80dc0314-4f51-4134-9b8c-a8896eb913b2/","/comparateur/assurance-auto-95d8491d-6775-41c9-98a2-e614b1584f33/","/comparateur/assurance-auto-1f9414b9-7565-4c24-9323-aeb7d7d41330/"],
    component: () => import("/opt/build/repo/pages/comparateur/car.vue"),
    children: [
  {
    name: formnTXmdrPqXgMeta?.name,
    path: "form",
    component: () => import("/opt/build/repo/pages/comparateur/car/form.vue"),
    children: [
  {
    name: "comparateur-car-form",
    path: "",
    component: () => import("/opt/build/repo/pages/comparateur/car/form/index.vue")
  },
  {
    name: "comparateur-car-form-subscribers-profile-informations",
    path: "subscribers/:profile()/informations",
    component: () => import("/opt/build/repo/pages/comparateur/car/form/subscribers/[profile]/informations.vue")
  },
  {
    name: "comparateur-car-form-vehicle-brand",
    path: "vehicle/brand",
    component: () => import("/opt/build/repo/pages/comparateur/car/form/vehicle/brand.vue")
  },
  {
    name: "comparateur-car-form-vehicle-dates",
    path: "vehicle/dates",
    component: () => import("/opt/build/repo/pages/comparateur/car/form/vehicle/dates.vue")
  },
  {
    name: "comparateur-car-form-vehicle-energy",
    path: "vehicle/energy",
    component: () => import("/opt/build/repo/pages/comparateur/car/form/vehicle/energy.vue")
  },
  {
    name: "comparateur-car-form-vehicle-model",
    path: "vehicle/model",
    component: () => import("/opt/build/repo/pages/comparateur/car/form/vehicle/model.vue")
  },
  {
    name: "comparateur-car-form-vehicle-power",
    path: "vehicle/power",
    component: () => import("/opt/build/repo/pages/comparateur/car/form/vehicle/power.vue")
  },
  {
    name: "comparateur-car-form-vehicle-usage",
    path: "vehicle/usage",
    component: () => import("/opt/build/repo/pages/comparateur/car/form/vehicle/usage.vue")
  },
  {
    name: "comparateur-car-form-vehicle-version",
    path: "vehicle/version",
    component: () => import("/opt/build/repo/pages/comparateur/car/form/vehicle/version.vue")
  }
]
  },
  {
    name: _91uuid_93tWxCkawGvNMeta?.name,
    path: "restitution/:uuid()",
    component: () => import("/opt/build/repo/pages/comparateur/car/restitution/[uuid].vue"),
    children: [
  {
    name: "comparateur-car-restitution-uuid",
    path: "",
    component: () => import("/opt/build/repo/pages/comparateur/car/restitution/[uuid]/index.vue")
  },
  {
    name: "comparateur-car-restitution-uuid-mise-en-relation-mode-offerId",
    path: "mise-en-relation-:mode()/:offerId()",
    component: () => import("/opt/build/repo/pages/comparateur/car/restitution/[uuid]/mise-en-relation-[mode]/[offerId].vue")
  }
]
  }
]
  },
  {
    name: formdbOGjJwhQHMeta?.name,
    path: "example/form",
    component: () => import("/opt/build/repo/pages/comparateur/example/form.vue"),
    children: [
  {
    name: "comparateur-example-form",
    path: "",
    component: () => import("/opt/build/repo/pages/comparateur/example/form/index.vue")
  },
  {
    name: "comparateur-example-form-information",
    path: "information",
    component: () => import("/opt/build/repo/pages/comparateur/example/form/information.vue")
  },
  {
    name: "comparateur-example-form-profil-profil",
    path: "profil/:profil()",
    component: () => import("/opt/build/repo/pages/comparateur/example/form/profil/[profil].vue")
  },
  {
    name: "comparateur-example-form-test",
    path: "test",
    component: () => import("/opt/build/repo/pages/comparateur/example/form/test.vue")
  }
]
  },
  {
    name: "comparateur-example-restitution-uuid",
    path: "example/restitution/:uuid()",
    component: () => import("/opt/build/repo/pages/comparateur/example/restitution/[uuid].vue"),
    children: [
  {
    name: "comparateur-example-restitution-uuid-proposal-mode",
    path: "proposal/:mode()",
    component: () => import("/opt/build/repo/pages/comparateur/example/restitution/[uuid]/proposal/[mode].vue")
  }
]
  },
  {
    name: "comparateur-funeral-v2",
    path: "funeral-v2",
    alias: ["/comparateur/assurance-obseques-5104f28d-e5b8-4a54-8c17-ac8122a40413/","/comparateur/assurance-obseques-00ae77aa-5d07-4fed-bf2a-01d7ffdaa38f/","/comparateur/assurance-obseques-2f75f343-e678-42ed-ab95-d5e9fcf2e53e/","/comparateur/assurance-obseques-1645020894689/","/comparateur/assurance-obseques-1645626271346/","/comparateur/assurance-obseques-1645632964634/","/comparateur/assurance-obseques-1645797357314/","/comparateur/assurance-obseques-773f3060-f7c2-4bea-9ece-402b02b34097/","/comparateur/assurance-obseques-c4cd9399-2fde-4081-9695-086984ea5da6/","/comparateur/assurance-obseques-1647508725778/","/comparateur/assurance-obseques-8e026604-f181-4f34-9cb6-76b85a3be3f4/","/comparateur/assurance-obseques-d19c66a6-54b6-4326-b658-0f019db20201/","/comparateur/assurance-obseques-d3d8abfa-a83f-46dc-bd47-c606e1e169a5/","/comparateur/assurance-obseques-f3bec91e-b66a-4cd8-8ebb-2014e61b8f05/","/comparateur/assurance-obseques-3e4b9161-a4a2-476b-940c-b4af38508377/","/comparateur/assurance-obseques-1006280c-388b-4a05-9cc0-c90f485aaf74/","/comparateur/assurance-obseques-1ba98b90-31c9-4923-a814-f4b23193d689/","/comparateur/assurance-obseques-b8d5b49b-1fa3-4ee6-a974-061af7288d82/","/comparateur/assurance-obseques-2eff2ef5-2153-4b6f-8849-83ee818ed85c/","/comparateur/assurance-obseques-b58d0d83-2973-42cd-9ec6-232de0012523/"],
    component: () => import("/opt/build/repo/pages/comparateur/funeral-v2.vue"),
    children: [
  {
    name: formvJJZ5o3ruHMeta?.name,
    path: "form",
    component: () => import("/opt/build/repo/pages/comparateur/funeral-v2/form.vue"),
    children: [
  {
    name: "comparateur-funeral-v2-form",
    path: "",
    component: () => import("/opt/build/repo/pages/comparateur/funeral-v2/form/index.vue")
  },
  {
    name: "comparateur-funeral-v2-form-information",
    path: "information",
    component: () => import("/opt/build/repo/pages/comparateur/funeral-v2/form/information.vue")
  }
]
  },
  {
    name: _91uuid_93E86CLDUksmMeta?.name,
    path: "restitution/:uuid()",
    component: () => import("/opt/build/repo/pages/comparateur/funeral-v2/restitution/[uuid].vue"),
    children: [
  {
    name: "comparateur-funeral-v2-restitution-uuid",
    path: "",
    component: () => import("/opt/build/repo/pages/comparateur/funeral-v2/restitution/[uuid]/index.vue")
  },
  {
    name: "comparateur-funeral-v2-restitution-uuid-mise-en-relation-mode-offerId",
    path: "mise-en-relation-:mode()/:offerId()",
    component: () => import("/opt/build/repo/pages/comparateur/funeral-v2/restitution/[uuid]/mise-en-relation-[mode]/[offerId].vue")
  }
]
  }
]
  }
]
  },
  {
    name: "iframe-tester",
    path: "/iframe-tester",
    component: () => import("/opt/build/repo/pages/iframe-tester.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  }
]